import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

interface Address {
  address: string;
  coordinates: {
    lat: number;
    long: number;
  };
  kind: string;
}

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function AddressSearchInput({
  onSelect,
  defaultValue,
  placeholder,
  error,
}: {
  defaultValue?: string;
  onSelect: (value: Address | null) => void;
  placeholder?: string;
  error?: boolean;
}) {
  const [value, setValue] = React.useState<Address | null>(null);
  const [inputValue, setInputValue] = React.useState(defaultValue ?? "");
  const debouncedInputValue = useDebounce(inputValue, 400);

  const { data: fetchedOptions } = useQuery({
    queryKey: ["addressSearch", debouncedInputValue],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/locality?search=${debouncedInputValue}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data as Address[];
    },

    enabled: debouncedInputValue.trim().length > 0,
    staleTime: 300000,
  });

  const options = React.useMemo(() => {
    if (debouncedInputValue.trim() === "") {
      return value ? [value] : [];
    }
    return value ? [value, ...(fetchedOptions || [])] : fetchedOptions || [];
  }, [debouncedInputValue, fetchedOptions, value]);

  React.useEffect(() => {
    if (options.length === 1) {
      setValue(options[0]);
      onSelect(options[0]);
    }
  }, [options, onSelect]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.address
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Не найдено"
      onChange={(event, newValue: Address | null) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder ?? "Введите адрес"}
          fullWidth
          error={error}
        />
      )}
      inputValue={inputValue}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item sx={{ display: "flex", width: 44 }}>
              <LocationOnIcon sx={{ color: "text.secondary" }} />
            </Grid>
            <Grid
              item
              sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
            >
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {option.address}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}
