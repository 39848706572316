export type ColumnDef<T> = {
  key: keyof T;
  regex?: RegExp;
  required?: boolean;
};

export function validateFormData<T>(
  columns: ColumnDef<T>[],
  formData: T
): Partial<Record<keyof T, string>> {
  const errors: Partial<Record<keyof T, string>> = {};

  columns.forEach((column) => {
    if (
      column.regex &&
      !(formData[column.key] as unknown as string)?.trim().match(column.regex)
    ) {
      console.log(formData[column.key]);
      errors[column.key] = "Неверный формат";
      return;
    }
    if (column.required && !formData[column.key]) {
      errors[column.key] = "Обязательное поле";
      return;
    }
    errors[column.key] = "";
  });

  return errors;
}
