import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AddModal from "../../components/AddModal";
import { FieldOfActivity, Organization } from "./Organization";
import {
  addNewOrganization,
  fetchFields,
  fetchOrganizations,
  removeOrganization,
  updateOrganization,
  updateOrganizationStatus,
} from "../../api";
import DeleteDialog from "../../components/DeleteDialog";
import { STATUSES } from "./constants";

export enum Sorting {
  "ASC" = "asc",
  "DESC" = "desc",
  "NONE" = "",
}

const OrganizationsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [isBanModalOpen, setBanModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState<Organization | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [sorting, setSorting] = useState<Sorting>(Sorting.NONE);

  const handleEditClick = (userData: Organization) => {
    setCurrentData(userData);
    setEditModalOpen(true);
  };

  const handleBanClick = (userData: Organization) => {
    setCurrentData(userData);
    setBanModalOpen(true);
  };

  const handleEditStatusClick = (data: Organization) => {
    setCurrentData(data);
    setEditStatusModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setCurrentData(null);
    setEditModalOpen(false);
    setBanModalOpen(false);
    setAddModalOpen(false);
    setEditStatusModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = async (updatedData: Organization) => {
    if (updatedData.locality_address.kind !== "house") {
      return;
    }

    const place = { ...updatedData.locality_address };

    updatedData.locality_address = place.address;
    updatedData.locality_lat = place.coordinates.lat;
    updatedData.locality_long = place.coordinates.long;

    updatedData.field_of_activity = fields.find(
      (field: { value: string }) =>
        field.value === updatedData.field_of_activity
    )?.id;
    console.log(updatedData);
    changeOrganization.mutate(updatedData);
    setEditModalOpen(false);
  };

  const handleSaveStatus = (updateddata: Organization) => {
    if (!updateddata || !updateddata.id) return;

    changeOrganizationStatus.mutate({
      id: updateddata.id,
      status:
        STATUSES.find((status) => status.label === updateddata.status)?.value ??
        "rejected",
    });
    setEditModalOpen(false);
  };

  const handleConfirmBan = () => {
    if (!currentData) return;
    deleteOrganization.mutate(currentData.id);
    setBanModalOpen(false);
  };

  const handleAdd = async (
    updateddata: Pick<
      Organization,
      | "attachments"
      | "organization_name"
      | "phone"
      | "website"
      | "working_hours"
      | "comment"
      | "photos"
      | "locality_address"
      | "locality_lat"
      | "locality_long"
      | "field_of_activity"
    >
  ) => {
    if (updateddata.locality_address?.kind !== "house") {
      return;
    }
    const place = { ...updateddata.locality_address };

    updateddata.locality_address = place.address;
    updateddata.locality_lat = place.coordinates.lat;
    updateddata.locality_long = place.coordinates.long;
    updateddata.field_of_activity = fields.find(
      (field: { value: string }) =>
        field.value === updateddata.field_of_activity
    )?.id;
    addOrganization.mutate(updateddata);
    handleClose();
  };

  const columns: Column<Organization>[] = [
    {
      label: "Название",
      render: (organization) => <>{organization.organization_name}</>,
    },
    {
      label: "Веб-сайт",
      render: (organization) => <>{organization.website}</>,
    },
    {
      label: "Телефон",
      render: (organization) => <>{organization.phone}</>,
    },
    {
      label: "Фото",
      render: (organization) => (
        <>
          {organization?.photos?.map((media) => (
            <img src={media} alt={media} style={{ width: "100px" }} />
          ))}
        </>
      ),
    },
    {
      label: "Статус",
      render: (organization) => (
        <>
          {STATUSES.find((status) => status.value === organization.status)
            ?.label ?? "rejected"}
        </>
      ),
    },
    {
      label: "Действия",
      render: (organization) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(organization)}
            key={organization.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleBanClick(organization)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label="status"
            onClick={() => handleEditStatusClick(organization)}
            key={organization.id + "status"}
          >
            <PlaylistAddCheckIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();

  const { data: fields } = useQuery({
    queryKey: ["fields"],
    queryFn: () => fetchFields(),
  });

  const { data, error, isLoading } = useQuery({
    queryKey: ["organizations", rowsPerPage, nextPageToken, sorting],
    queryFn: () => fetchOrganizations(rowsPerPage, sorting, nextPageToken),
  });

  const addOrganization = useMutation({
    mutationFn: addNewOrganization,
    mutationKey: ["organizations"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });

  const deleteOrganization = useMutation({
    mutationFn: removeOrganization,
    mutationKey: ["organizations"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });

  const changeOrganization = useMutation({
    mutationFn: updateOrganization,
    mutationKey: ["organizations"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });
  const changeOrganizationStatus = useMutation({
    mutationFn: updateOrganizationStatus,
    mutationKey: ["organizations"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });

  const handleChangeSorting = (value: Sorting) => {
    setSorting(value);
  };
  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?(www\\.)?([-a-zA-Z0-9а-яА-Я]{2,256})\\.([-a-zA-Z0-9а-яА-Я]{2,4})([-a-zA-Z0-9а-яА-Я@:%_\\+.~#?&//=]*)?$",
    "gmiu"
  );
  const phoneRegex = new RegExp("^\\+7\\d{10}$", "iu");

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Организации</h1>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          gap: "14px",
        }}
      >
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Сортировка</InputLabel>
          <Select
            value={sorting}
            onChange={(e) => handleChangeSorting(e.target.value as Sorting)}
            label="Сортировка"
          >
            <MenuItem value={Sorting.NONE}>Нет</MenuItem>
            <MenuItem value={Sorting.ASC}>По возрастанию</MenuItem>
            <MenuItem value={Sorting.DESC}>По убыванию</MenuItem>
          </Select>
        </FormControl>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DataTable
          columns={columns}
          data={data?.organizations ?? []}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.page_token);
              setTokens((prev) => [...prev, data.page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.num_elements}
        />
      )}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить организацию"
        defaultState={{ organization_name: "", phone: "", website: "" }}
        columns={[
          {
            label: "Название",
            key: "organization_name",
            editable: true,
            placeholder: "Текстовое, до 200 символов",
            regex: /^.{1,200}$/,
          },
          {
            label: "Телефон",
            key: "phone",
            editable: true,
            placeholder: "11 цифр, маска +7(...)...-..-..",
            regex: phoneRegex,
            defaultValue: "+7",
          },
          {
            label: "Веб-сайт",
            key: "website",
            editable: true,
            placeholder: "Текстовое, ссылка, до 200 символов",
            regex: urlRegex,
            defaultValue: "https://",
          },
          {
            label: "Рабочие часы",
            key: "working_hours",
            editable: true,
            placeholder: "Текстовое, до 200 символов",
            regex: /^.{1,200}$/,
          },
          {
            label: "Комментарий",
            key: "comment",
            editable: true,
            placeholder: "Текстовое, до 1000 символов",
          },
          {
            label: "Сфера деятельности",
            required: true,
            key: "field_of_activity",
            editable: true,
            type: "select",
            options: fields?.map((field: FieldOfActivity) => field.value) ?? [],
          },
          {
            label: "Адрес",
            key: "locality_address",
            editable: true,
            type: "address",
            required: true,
          },
          {
            label: "Файлы",
            key: "attachments",
            editable: true,
            type: "file",
          },
        ]}
        onSave={handleAdd}
      />
      {currentData && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование организации"
          data={{
            ...currentData,
            locality_address: currentData.locality?.address,
            field_of_activity: (
              currentData.field_of_activity as FieldOfActivity
            ).value,
          }}
          columns={[
            {
              label: "Название",
              key: "organization_name",
              editable: true,
              regex: /^.{1,200}$/,
            },
            {
              label: "Телефон",
              key: "phone",
              editable: true,
              regex: phoneRegex,
            },
            {
              label: "Веб-сайт",
              key: "website",
              editable: true,
              regex: urlRegex,
            },
            {
              label: "Рабочие часы",
              key: "working_hours",
              editable: true,
              regex: /^.{1,200}$/,
            },
            {
              label: "Комментарий",
              key: "comment",
              editable: true,
            },
            {
              label: "Адрес",

              key: "locality_address",
              editable: true,
              type: "address",
              required: true,
            },
            {
              label: "Сфера деятельности",
              key: "field_of_activity",
              editable: true,
              type: "select",
              options:
                fields?.map((field: FieldOfActivity) => field.value) ?? [],
              required: true,
            },
            {
              label: "Файлы",
              key: "attachments",
              editable: true,
              type: "file",
            },
            {
              label: "Медиа",
              key: "photos",
              editable: true,
              type: "order_simple",
            },
          ]}
          onSave={handleSave}
        />
      )}
      {currentData && (
        <EditModal
          open={isEditStatusModalOpen}
          onClose={handleClose}
          title="Редактирование статуса"
          data={{
            ...currentData,
            status:
              STATUSES.find((status) => status.value === currentData.status)
                ?.label ?? "rejected",
          }}
          columns={[
            {
              label: "Статус",
              key: "status",
              editable: true,
              type: "select",
              options: STATUSES.map((status) => status.label),
            },
          ]}
          onSave={handleSaveStatus}
        />
      )}
      <DeleteDialog
        open={isBanModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmBan}
        title="Удаление темы"
        text={`Вы точно хотите удалить организацию
        ${currentData?.organization_name}?`}
      />
    </div>
  );
};

export default OrganizationsPage;
